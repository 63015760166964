import { Instance, types } from 'mobx-state-tree';
import { FormState, FieldState } from 'formstate';
import { required } from '../../customer-information/shared/validators/RequiredValidator';

export const MemberAgreementOrderReviewStore = types
  .model('MemberAgreementOrderReviewStore', {
    agreementDirectMarketing: types.optional(types.boolean, false),
    termsAndConditionsAgreement: types.optional(types.boolean, false),
    confirmAccuracyAgreement: types.optional(types.boolean, false)
  })
  .views(self => ({
    get reactForm() {
      return new FormState({
        agreementdirectMarket: new FieldState(self.agreementDirectMarketing).validators(required()),
        termsAndConditionsAgreement: new FieldState(self.termsAndConditionsAgreement).validators(required()),
        confirmAccuracyCheckbox: new FieldState(self.confirmAccuracyAgreement).validators(required())
      });
    },
    haveAcceptedMemberAgreementOrderReview() {
      const form = this.reactForm;
      this.reactForm.validate();
      return (
        form.$.agreementdirectMarket.value &&
        form.$.termsAndConditionsAgreement.value &&
        form.$.confirmAccuracyCheckbox.value
      );
    }
  }))
  .actions(self => ({}));

export type MemberAgreementOrderReviewStore = Instance<typeof MemberAgreementOrderReviewStore>;
