import { Instance, types, flow, SnapshotIn } from 'mobx-state-tree';
import React from 'react';

import { ProductInfoErKitStore } from '../../../product-info/model/ProductInfoErKitStore';
import { enrollmentResource } from '../../../../infrastructure/http/EnrollmentResource';
import { ErUpsellModalStore } from './ErUpsellModalStore';
import { EssentialRewardKit } from './EssentialRewardKit';
import { getRootStore } from '../../../root/RootStoreUtils';
import { saveStepAndGoToCustomizeEr } from '../../common-er/custom-kit/CustomizeRedirector';

export const ErKitFormData = types.model('ErKitFormData', {
  IsEnrollingInEr: types.boolean,
  UsePremiumStarterKitAsErOrder: types.boolean,
  selectedKitId: types.maybe(types.number)
});

export const ErKitStore = types
  .model('ErKitStore', {
    enrollEssentialRewards: types.maybe(types.boolean),
    usePremiumKitAsErOrder: types.maybe(types.boolean),
    useFirsErOrderAsTemplate: types.maybe(types.boolean),
    erKits: types.array(EssentialRewardKit),
    selectedKit: types.maybe(types.reference(EssentialRewardKit)),
    showProductModal: types.optional(types.boolean, false),
    productInfo: types.maybe(ProductInfoErKitStore),
    canCustomizeErKit: types.boolean,
    canUsePskToAutoEnrollInEr: types.boolean,
    erUpsellEnabled: types.boolean,
    canCustomizeErWithPsk: types.boolean,
    customErType: types.maybe(types.enumeration('CustomErType', ['Custom', 'Psk'])),
    erUpsell: types.maybe(ErUpsellModalStore),
    useErSingleQuestion: types.optional(types.boolean, false),
    showErAgreementModel: types.optional(types.boolean, false),
    erAgreementAccepted: types.optional(types.boolean, false),
    isEnableForErAgreement: types.optional(types.boolean, false),
    isErAgreementDismissible: types.optional(types.boolean, false),
    isErAgreementDismissed: types.optional(types.boolean, false),
    scrollText: types.optional(types.string, ''),
    text: types.optional(types.string, '')
  })
  .views(self => ({
    get customKitSelected(): boolean {
      return self.customErType === 'Custom';
    },
    get customKitWithPskSelected(): boolean {
      return self.customErType === 'Psk';
    },
    get pskAsFirstErEligibleKitSelected(): boolean {
      const rootStore = getRootStore(self);

      return (
        !!rootStore.moduleStores.enrollmentKitSelection &&
        rootStore.moduleStores.enrollmentKitSelection.pskAsFirstErEligibleKitSelected
      );
    },
    get isMinimumErPvReached(): boolean {
      const rootStore = getRootStore(self);

      return (
        !!rootStore.moduleStores.enrollmentKitSelection &&
        rootStore.moduleStores.enrollmentKitSelection.isMinimumErPvReached
      );
    },
    get isMeoEnabled(): boolean {
      const rootStore = getRootStore(self);

      return (
        !!rootStore.moduleStores.enrollmentKitSelection && rootStore.moduleStores.enrollmentKitSelection.isMeoEnabled
      );
    },
    getDataToSubmit() {
      return {
        isEnrollingInEr: self.enrollEssentialRewards,
        usePremiumKitAsErOrder: self.usePremiumKitAsErOrder,
        useFirsErOrderAsTemplate: self.useFirsErOrderAsTemplate,
        selectedKitId: self.selectedKit && self.selectedKit.itemId,
        customErType: self.customErType,
        erAgreementAccepted: self.isEnableForErAgreement ? self.erAgreementAccepted : null,
        isErAgreementDismissed: self.isEnableForErAgreement ? self.isErAgreementDismissed : null
      };
    },

    get hasChosenKit(): boolean {
      return self.selectedKit !== undefined || self.customErType !== undefined;
    },

    get showUsePremiumStarterKitQuestion(): boolean {
      return (
        !!self.enrollEssentialRewards &&
        !!self.canUsePskToAutoEnrollInEr &&
        (!!this.pskAsFirstErEligibleKitSelected || (!!this.isMeoEnabled && !!this.isMinimumErPvReached))
      );
    },
    canProceed() {
      return self.enrollEssentialRewards === false || (self.enrollEssentialRewards === true && this.hasChosenKit);
    }
  }))
  .actions(self => ({
    updateEssentialRewards(value?: boolean) {
      self.enrollEssentialRewards = value;
    },
    updateShowErAgreementModel(value: boolean): Promise<boolean> {
      self.showErAgreementModel = value;
      return Promise.resolve(self.erAgreementAccepted);
    },
    updateErAgreementAcceptedValue(erAgreementAccepted: boolean, isErAgreementDismissed: boolean) {
      self.erAgreementAccepted = erAgreementAccepted;
      self.isErAgreementDismissed = isErAgreementDismissed;
      if (erAgreementAccepted || isErAgreementDismissed) {
        self.showErAgreementModel = false;
        const rootStore = getRootStore(self);
        if (self.customErType === 'Custom') {
          saveStepAndGoToCustomizeEr(rootStore);
        } else {
          rootStore.stepsManager.nextStep();
        }
      }
    },
    updateUsePremiumStarterKit(value?: boolean) {
      self.usePremiumKitAsErOrder = value;
    },
    updateCanCustomizeErKit(value: boolean) {
      self.canCustomizeErKit = value;
    },
    updateUseFirsErOrderAsTemplate(value?: boolean) {
      self.useFirsErOrderAsTemplate = value;
      if (value) {
        this.selectCustomizeKit();
      } else {
        self.customErType = undefined;
        self.selectedKit = undefined;
      }
    },
    choseKit(kit?: EssentialRewardKit) {
      self.selectedKit = kit;
      self.customErType = undefined;
      this.hideProductInfo();
    },
    showProductInfo: flow(function* showProductInfo(kit: EssentialRewardKit): any {
      const { locale } = getRootStore(self).localeManager.language;
      const partNumber = kit.upgradeComponent ? kit.upgradeComponent.partNumber : kit.partNumber;
      const response = yield enrollmentResource.get<SnapshotIn<typeof ProductInfoErKitStore>>(
        `/product-info/${locale}/${partNumber}`
      );

      self.productInfo = ProductInfoErKitStore.create({
        kit: kit.itemId,
        ...response.data
      });
      self.showProductModal = true;
    }),
    prepareResults(): Promise<boolean> {
      if (!self.erUpsell) {
        return Promise.resolve(true);
      }
      return self.erUpsell.showUpsellIfNecessary(self.hasChosenKit);
    },
    hideProductInfo() {
      self.productInfo = undefined;
      self.showProductModal = false;
    },
    selectCustomizeKit() {
      self.customErType = 'Custom';
      self.selectedKit = undefined;
    },
    selectCustomizeKitWithPsk() {
      self.customErType = 'Psk';
      self.selectedKit = undefined;
    },
    afterCreate() {
      if (self.erUpsellEnabled) {
        self.erUpsell = ErUpsellModalStore.create();
      }
    }
  }));

export type ErKitStore = Instance<typeof ErKitStore>;

export const ErKitStoreContext = React.createContext(undefined as ErKitStore | undefined);
