import { Instance, types } from 'mobx-state-tree';
import { Currency } from '../../../../reference/Currency';

export const OrderItem = types
  .model({
    name: types.string,
    partNumber: types.string,
    isPromotional: types.boolean,
    imageUrl: types.optional(types.string, ''),
    price: types.number,
    totalPrice: types.number,
    currency: types.reference(Currency),
    quantity: types.number
  })
  .named('OrderItem');

export type OrderItem = Instance<typeof OrderItem>;
