import { Router, createRouter } from '@yl/react-router';
import { addDisposer, Instance, onSnapshot, types } from 'mobx-state-tree';

import { EnrollmentRoutes, routes } from '../routing/routes';
import { cancelEnrollment, getNextEnrollmentStatus } from '../customer-enrollment-status/EnrollmentStatusManager';
import { EnrollmentStatus, EnrollmentStatusFromServer } from '../customer-enrollment-status/EnrollmentStatus';
import { StoreSpecificConfig } from '../customer-enrollment-status/StoreSpecificConfig';
import { EnrollmentReferenceStore } from '../../reference/EnrollmentReferenceStore';
import { StepsManagerStore } from '../enrollment-process/steps-management/model/StepsManagerStore';
import { ModulesStore } from '../enrollment-process/module-management/model/ModuleStore';
import { LocaleManager } from '../locale-management/locale-manager/LocaleManager';
import { getRootStore } from './RootStoreUtils';

export const EnrollmentRootStore = types
  .model('EnrollmentRootStore', {
    router: Router,
    reference: EnrollmentReferenceStore,
    stepsManager: StepsManagerStore,
    moduleStores: types.optional(ModulesStore, () => ModulesStore.create()),
    enrollmentStatus: EnrollmentStatus,
    windowWidth: types.optional(types.number, window.innerWidth),
    localeManager: LocaleManager,
    showSpinner: types.optional(types.boolean, false)
  })
  .views(self => ({
    get isMobileSized(): boolean {
      return self.windowWidth < 768;
    }
  }))
  .actions(self => ({
    updateWindowWidth(width: number): void {
      self.windowWidth = width;
    },
    cancelEnrollment(toRoot: boolean, country?: string, language?: string) {
      this.spin();
      const { enrollmentId, tag } = self.enrollmentStatus;
      cancelEnrollment(enrollmentId, toRoot, country, language, tag);
    },
    async switchEnrollment(enrollmentId: string) {
      this.spin();
      try {
        const enrollmentStatus = await getNextEnrollmentStatus(enrollmentId);
        const { bootstrapNextStore } = await import('../../bootstrapStore');
        await bootstrapNextStore(enrollmentStatus);
      } catch (error) {
        console.error(error);
        this.unspin();
      }
    },
    spin() {
      self.showSpinner = true;
    },
    unspin() {
      self.showSpinner = false;
    }
  }))
  .actions(self => ({
    redirectToMain() {
      self.cancelEnrollment(true, self.enrollmentStatus.countryIso, self.localeManager.language.locale);
    }
  }))
  .actions(self => ({
    afterCreate() {
      if (process.env.NODE_ENV === 'development') {
        addDisposer(
          self,
          // eslint-disable-next-line no-console
          onSnapshot(self, snapshot => console.log('Snapping', snapshot))
        );
      }
    }
  }));

export function createRootStore(
  enrollmentStatus: EnrollmentStatusFromServer,
  storeSpecificConfiguration: StoreSpecificConfig
): EnrollmentRootStore {
  const router = createRouter(routes, '/apps/enrollment');
  const localeManager = {
    languages: storeSpecificConfiguration.languages,
    language: enrollmentStatus.locale
  };
  const reference = { currencies: [storeSpecificConfiguration.currency] };
  const stepsManager = {
    steps: storeSpecificConfiguration.steps,
    currentStep: enrollmentStatus.currentStep
  };
  return EnrollmentRootStore.create({
    enrollmentStatus,
    stepsManager,
    reference,
    router,
    localeManager
  }) as EnrollmentRootStore;
}

export interface EnrollmentRootStore extends Instance<typeof EnrollmentRootStore> {
  router: Router<EnrollmentRoutes>;
}
