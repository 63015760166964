import { Instance, types } from 'mobx-state-tree';
import { OrderItem } from './model/OrderItem';

export const OrderSummaryStore = types
  .model('OrderSummaryStore', {
    enrollmentItems: types.array(OrderItem),
    essentialRewardItems: types.array(OrderItem),
    editStepNumber: types.number,
    hideEditStepButton: types.boolean,
    isMeoEnabled: types.boolean,
    usePremiumStarterKitAsErOrder: types.boolean
  })
  .views(self => ({
    get isErEnrollment() {
      return self.essentialRewardItems.length > 0;
    }
  }));

export type OrderSummaryStore = Instance<typeof OrderSummaryStore>;
